export const listdec = {
  YO7CW: 'MIHAI IOAN',
  YO8BND: 'ANDRISAN COSTEL',
  YO5QDI: 'IVANCIUC PETRU',
  YO4DG: 'GIGEA GABRIEL',
  YO3JOS: 'DUMITRU MIHAI',
  YO5PEU: 'DAMO KRISZTIAN',
  YO8BGD: 'ASOFIE EUGEN',
  YO2CK: 'LASCONI OVIDIU',
  YO7AQF: 'PREOTEASA AUGUSTIN',
  YO2BLX: 'CHIS IOAN',
  YO8BDQ: 'MIHUTA STELICA',
  YO3DIU: 'ARADOAIE MARIAN',
  YO5OKM: 'MOLNAR LADISLAU',
  YO4DW: 'CORNELIUS SPORIS',
  YO4RST: 'ROMEO GALES',
  YO2LDU: 'POPA FLORIN',
  YO7BEM: 'DUMITROVICI MIHAI',
  YO3AF: 'LUPOIU FLORIN',
  YO6PEG: 'FUEREA STELIAN',
  YO8SJR: 'NEAGU SILVIU',
  YO3CWT: 'DANIEL BORSOS',
  YO5DAS: 'CHIS MIHAI',
  YO7AKY: 'MARTOIU ALEXANDRU',
  YO5OHC: 'CHIOREAN VIOREL',
  YO3GCL: 'OLTEANU MIHAI',
  YO4BYW: 'ALIMAN STAN',
  YO5CEA: 'CRISTEA STEFEN',
  YO7IIY: 'FLORIN JECU',
  YO9BCM: 'BUCUR VIRGIL',
  YO8SDC: 'DAICIU CRISTIAN',
  YO8OU: 'EMIL LIVADARU',
  YO5GHA: 'DANUT UTEA',
  YO7MR: 'ROTARU MARIAN',
  YO2ARD: 'ARCANA DANIEL',
  YO7HHE: 'CONSTANTIN DANIEL',
  YO7NSP: 'STEFAN POPA',
  YO6FNF: 'TICUSAN DAVID',
  YO2AMU: 'ZASLO DORU',
  YO2SH: 'POPA DORIAN',
  YO5YM: 'MARCEL VESA',
  YO6BJG: 'WASS LADISLAU',
  YO3JW: 'FENYO STEFAN',
  YO2LGH: 'CURTU IOAN',
  YO8SGM: 'DUMITRESCU MARIAN',
  YO9AYN: 'DINCA ION',
  YO9BI: 'NITA CONSTANTIN',
  YO9LL: 'COLTEANU MARIAN',
  YO2LXW: 'MIHAI CAROL',
  YO4TL: 'SARBU VICTOR',
  YO2MJZ: 'USCA CORNEL',
  YO4SI: 'RUCAREANU MIRCEA',
  YO7PED: 'POPOVICI STEFAN',
  YO8SGV: 'SORIN MARIUS T MOISA',
  YO7ISK: 'DORU VISAN',
  YO7HUZ: 'ALMASI NICOLAIE',
  YO9BHI: 'BELEI AUREL',
  YO4FPF: 'ROCA DAN',
  YO2MIT: 'ROMANITAN MIRCEA-CALIN',
  YO8AAZ: 'MANOLACHE IONEL',
  YO2LUW: 'NUTI  IOAN LIVIU',
  YO3BY: 'ALEXANDRESCU IOAN',
  YO4MM: 'DUMITRESCU LESOVICI',
  YO2LTA: 'POPA DANIEL',
  YO9FTR: 'LIVIUS BALEA',
  YO3CCB: 'ION CONSTANTIN',
  YO2MFC: 'MUNTEAN PAVEL',
  YO9CLG: 'CEAUSU PANDELE',
  YO4AJ: 'GEANTA CORNEL',
  YO4BXX: 'CONRAD CORNELIU',
  YO8YRY: 'MAFTEI IRINEL',
  YO6OBG: 'GEORGE - CHARLES ZILAHI',
  YO8CKR: 'PREUTESCU VASILE',
  YO9CMF: 'MIHAI PAUL',
  YO4RIP: 'VASILENIUC VIOREL',
  YO5OA: 'SARCA ALEXA',
  YO2LQI: 'CORNEL-IOAN CAMPUREAN',
  DL2LDE: 'DANIEL VUESCU',
  YO5OJC: 'MOLNAR IOAN',
  YO3GNF: 'URSULEAN GHEORGHE',
  YO9WHI: 'TRIFU EUGEN-MIHAI',
  YO3HEX: 'GABRIEL TUDORAN',
  YO5BFJ: 'STOICESCU ADRIAN',
  DO5PNS: 'SEBASTIAN NACIU',
  YO5OST: 'CENUSA ANTONIU',
  YO2LCQ: 'ARDELEAN IOAN',
  YO9FDX: 'AGU ROMICA FLORIN',
  YO8CDA: 'CRISTIAN ANTONAS',
  YO8KSV: 'RADIOCLUBUL HERTZ',
  YQ0U: 'A.J.R. ALBA',
  YO5KAI: 'Radioclub C.S.M. CLUJ-NAPOCA',
  'YO7KSM/p': 'C. S. MUSCEL (CAMPULUNG) ',
  YO7KAJ: 'C. S. M. CRAIOVA',
  'YO2KQT/p': 'RADIOCLUBUL QSO BANAT',
  YO9KPB: 'RADIOCLUBUL MUNICIPAL CAMPINA',
  YO3FLR: 'SIMION CRISTIAN',
};

export const listincoronare = {
  YO8BGD: 'CIOBANU ION',
  YO3DIU: 'ARADOAIE MARIAN',
  YO3APJ: 'SINITARU ADRIAN',
  YO8SDC: 'DAICIU CRISTIAN DAN',
  YO9XC: 'BURDUCEA OVIDIU',
  YO7HBY: 'STAN COSTINEL',
  YO7LDT: 'MARINESCU VALERICA',
  YO5CEA: 'CRISTEA STEFAN',
  YO8SJR: 'NEAGU SILVIU',
  YO8SGM: 'DUMITRESCU MARIAN',
  YO3CWT: 'DANI BORSOS',
  YO9BI: 'NITA CONSTANTIN',
  YO2LGH: 'CURTU IOAN',
  YO6BJG: 'WASS LADISLAU',
  YO5QDI: 'IVANCIUC PETRU',
  YO7AKY: 'MARTOIU ALEXANDRU',
  YO7HUZ: 'ALMASI NICOLAIE',
  YO5GHA: 'UTEA DANUT',
  YO9CLG: 'CEAUSU PANDELE – PUIU',
  YO4AH: 'ISPIR BORIS',
  YO6FNF: 'TICUSAN DAVID',
  YO6QCD: 'STOIAN LIVIU CONSTANTIN',
  YO7PED: 'POPOVICI STEFAN',
  YO6EL: 'COSMIN BALABAN',
  YO3JW: 'FENYO STEFAN',
  YO8BFB: 'VIOREL TOMOZEI',
  YO2LQR: 'CIPRIAN DUGHIR',
  YO6OIW: 'MAGIRESCU IULIAN',
  YO8CKR: 'PREUTESCU VASILE',
  YO5OST: 'CENUSA ANTONIU',
  YO3JOS: 'DUMITRU MIHAI DANIEL',
  YO6CFB: 'BAKO-SZABO LASZLO',
  YO7KBS: 'C. S. TERMO DROBETA-TURNU SEVERIN',
  YO4SLL: 'STANCIU MARICA',
  YO8BDQ: 'MIHUTA STELICA',
  YO7LYM: 'RADULESCU CONSTANTIN',
  YO9KXC: 'C.S. UNIVERS B-90 BUZAU',
  YO6LA: 'GRIGORE TATARU',
  YO8RGW: 'ONOFREI GHEORGHE',
};

export const list1dec2024 = {
  YO3GCL: 'OLTEANU MIHAI',
  YO7HBY: 'STAN COSTINEL',
  YO3LR: 'CRISTIAN SIMION',
  YO7AQF: 'PREOTEASA AUGUSTIN',
  YO2BLX: 'CHIS IOAN',
  YO3AF: 'FLORIN LUPOIU',
  YO7BEM: 'DUMITROVICI MIHAI',
  YO8SDC: 'DAICIU CRISTIAN DAN',
  YO6FNF: 'TICUSAN DAVID',
  YO2GL: 'DAROCZI CAROL',
  YO3DIU: 'ARADOAEI MARIAN',
  YO2LIM: 'TICA TODOR SORIN',
  YO7LDT: 'VALERICA MARINESCU',
  YO5DAS: 'CHIS MIHAI DANUT',
  YO5GHA: 'UTEA DAN',
  YO2ABO: 'ANTON PAVEL DIHEL',
  YO2MPK: 'URBAN RAUL IOHAN',
  YO8BDW: 'CRASI MILAN',
  YO5DGE: 'DAN SABAU',
  YO3AK: 'NEGOESCU SANDU',
  YO9FTR: 'LIVIUS BALEA',
  YO9XC: 'BURDUCEA OVIDIU VASILE',
  YO5QDI: 'PETRU IVANCIUC',
  YO2LTA: 'POPA DANIEL',
  YO2CJX: 'VIRGIL NESTERIUC',
  YO2LDU: 'POPA FLORIN',
  YO7PED: 'POPOVICI STEFAN',
  YO7MR: 'MARIAN ROTARU',
  YO2MFC: 'PAVEL MUNTEAN',
  YO9BI: 'COSTI NITA',
  YO2LCV: 'IOAN MUNTEAN',
  YO2BCO: 'BOGDANESCU CONSTANTIN',
  YO6PEG: 'STELIAN FUEREA',
  YO4SI: 'MIRCEA RUCAREANU',
  YO9NCL: 'GABRIEL TANASE',
  YO2MJZ: ' NELU USCA',
  YO4MM: 'LESOVICI DUMITRU',
  YO5YM: 'MARCEL VESA',
  YO4GOU: 'DAN DRAGHIA',
  YO7HUZ: 'ALMASI NICOLAIE',
  YO3BA: 'COSMIN ANCUTA',
  YO6DBL: 'SERESAN IONEL',
  YO9FGY: 'GIURGEA ALEXANDRU',
  YO9CWY: 'DAN MOTRONEA',
  YO5BTZ: 'MOLDOVAN DAVID',
  YO8CKR: 'PREUTESCU VASILE-BAZIL',
  YO8BDV: 'DINCA VIOREL',
  YO5PVZ: 'COROIAN AUGUSTIN',
  YO3AEK: 'MITROI RADU MARIAN',
  YO4SLL: 'STANCIU MIRICA',
  YO7KBS: 'OP YO7NSP, POPA STEFAN',
  YO5KAL: 'RC ASOCIATIA LIMBENII',
  YO9KPB: 'RC MUNICIPAL CAMPINA',
};

export const listcarnavallimba2024 = {
  YO7BEM: 'DUMITROVICI MIHAI - BEM -',
  YO8SDC: 'DAICIU CRISTIAN DAN',
  YO8BGD: 'EUGEN ASOFIE',
  YO7LDT: 'VALERICA MARINESCU',
  YO9CWY: 'DAN MOTRONEA',
  YO6POK: 'CHIPER MIRCEA',
  YO2LTA: 'POPA DANIEL',
  YO5GHA: 'UTEA DAN',
  YO9BI: 'COSTI NITA',
  YO9XC: 'BURDUCEA OVIDIU VASILE',
  YO8RGW: 'ONOFREI GHEORGHE',
  YO5YM: 'VESA MARCEL',
  YO4SI: 'MIRCEA RUCAREANU',
  'YO5OUC/P': 'CRISAN NICOLAE',
  'YO5OUC/p': 'CRISAN NICOLAE',
  YO4GOU: 'DAN DRAGHIA',
  YO2GL: 'DAROCZI CAROL',
  YO6KNE: 'SPORT CLUB MIERCUREA-CIUC',
  YO8KZG: 'DAN TEODORIAN ONOFREI',
  YO4KAK: 'CSR BRAILA',
  YO3KWJ: 'FENYO STEFAN',
  YO2ARD: 'ARCANA DANIEL',

};

export const listavramiancu2024 = {
  YO8SDC: 'DAICIU CRISTIAN DAN',
  YO7BEM: 'DUMITROVICI MIHAI - BEM -',
  YO3AF: 'FLORIN LUPOIU',
  YO8CT: 'CRISTIAN TOSU',
  YO2LIM: 'TICA TODOR SORIN',
  YO5OKM: 'MOLNAR LADISLAV GEO',
  YO6BGT: 'TANKO STEFAN',
  YO6LRV: 'BERGER DENIS ADRIAN',
  YO8CKR: 'PREUTESCU VASILE-BAZIL',
  YO6PEG: 'FUEREA STELIAN',
  YO7LDT: 'VALERICA MARINESCU',
  YQ5A: 'OP. CRITEA STEFAN YO5CEA',
  YO5YM: 'MARCEL VESA',
  YO8BFB: 'VIOREL TOMOZEI',
  YO9IAB: 'MELNICOF VASILE',
  YO8RGW: 'ONOFREI GHEORGHE',
  YO5GHA: 'UTEA DAN',
  YO9CWY: 'DAN MOTRONEA',
  YO8BND: 'ANDRISAN COSTEL',
  YO3CCB: 'CONSTANTIN ION',
  YO4GOU: 'DAN DRAGHIA',
  YO8CVY: 'OTELEANU NECULAI',
  YO8AB: 'GRECULEAC MUGUREL',
  YO8YRY: 'MAFTEI IRINEL',
  YO6KNE: 'SPORT CLUB MIERCUREA-CIUC',
  YO3KWJ: 'FENYO STEFAN'
};

export const listdec2 = {
  YO3FFF: 'NEGRU CRISTIAN',
  YO7BEM: 'DUMITROVICI MIHAI',
  YO3DIU: 'ARADOAIE MARIAN',
  YO2CK: 'LASCONI OVIDIU',
  YO2AA: 'DOBOLI BENIAMIN',
  YO2YA: 'IACOB MARIUS',
  YO8BGD: 'ASOFIE EUGEN',
  YO8SDC: 'DAICIU CRISTIAN',
  YO7IIY: 'JECU  FLORIN',
  YO7AQF: 'PREOTEASA AUGUSTIN',
  YO2SH: 'POPA DORIAN-PETRICA',
  YO5OKM: 'MOLNAR LADISLAU',
  YO5DAS: 'CHIS MIHAI',
  YO2DD: 'JULA DOREL',
  YO2BLX: 'CHIS IOAN',
  YO6OIW: 'MAGIRESCU IULIAN',
  YO2MAX: 'CIMPONER RAZVAN',
  YO7HBY: 'STAN COSTINEL',
  YO4FZX: 'CLOSCARU MARIN',
  YO9BHI: 'BELEI AUREL',
  YO3AF: 'LUPOIU FLORIN GHEORGHE',
  YO8PBT: 'FLOCEA PETRU',
  YO6FNF: 'TICUSAN DAVID',
  YO3CWT: 'BORSOS DANI',
  YO9GVN: 'IVAN MARIUS FLORIN',
  YO4DW: 'SPORIS CORNELIUS',
  YO5YM: 'VESA MARCEL',
  YO9GMI: 'PONICI DORIN',
  YO7HYC: 'MANOLOIU VLAD',
  YO4BYW: 'ALIMAN STAN',
  YO2CJX: 'NESTERIUC VIRGIL',
  YO4AJ: 'GEANTA CORNEL',
  YO9FTR: 'BALEA LIVIUS',
  YO6BJG: 'WASS LADISLAU',
  YO8BFB: 'TOMOZEI VIOREL',
  YO2MJZ: 'USCA CORNEL',
  YO4BXX: 'CONRAD CORNELIU',
  YO8VET: 'VEDEANU TUDOR',
  YO9BI: 'NITA CONSTANTIN',
  YO5QDI: 'IVANCIUC PETRU',
  YO8CHD: 'MOLNAR IOAN',
  YO4SI: 'RUCAREANU MIRCEA',
  YO2GL: 'DAROCZI CAROL',
  YO8CKR: 'PREUTESCU VASILE',
  YO2BWC: 'POPOVICI AUREL',
  YO7PED: 'POPOVICI STEFAN',
  YO2LTA: 'POPA DANIEL',
  YO9CWY: 'MOTRONEA DANIEL',
  YO5BTZ: 'MOLDOVAN DAVID',
  YO4TL: 'SARBU VICTOR',
  YO3APJ: 'SINITARU ADRIAN',
  YO4GOU: 'DRAGHIA DAN – MUGUR',
  YO2LXW: 'CAROL MIHAI',
  YO9XC: 'BURDUCEA OVIDIU',
  YO5OJC: 'MOLNAR IOAN',
  YO2LUW: 'NUTI IOAN LIVIU',
  YO4MM: 'LESOVICI DUMITRU',
  YO8AXP: 'NEACSU LAURENTIU',
  YO9CLG: 'CEAUSU PANDELE',
  YO6DEA: 'VULKAN ADALBERT-CSABA',
  YO2AMU: 'ZASLO DORU EMIL',
  YO9IUP: 'PAUNESCU BOGDANEL',
  YO5CYW: 'MOGOS TUDOR',
  YO8BDV: 'DINCA VIOREL',
  YO9FDG: 'VOICU VASILE',
  YO6POK: 'CHIPER MIRCEA',
  YO3GNF: 'URSULEAN GHEORGHE',
  YO8WHT: 'PALAMARIU VICTOR IULIAN',
  YO6OBG: 'ZILAHI GEORGE',
  YO5GHA: 'UTEA DAN',
  YO5BFJ: 'STOICESCU ADRIAN',
  YO2CLL: 'BEHAWETS OTTO',
  YO6TJJ: 'AVIRVAREI ANDREI',
  YO2KQK: 'CLUBUL COPIILOR PETROSANI',
  YO3KWJ: 'F. SERVICE /YO3JW',
  YO5KAI: 'RADIOCLUB CSM  CLUJ-NAPOCA',
  YR1400VT: 'RADIOCLUBUL SCOLAR GHEORGHE SINCAI, VIA TRANSILVANICA',
  YO8KZG: 'ASOCIATIA RADIOCLUBUL ION CREANGA TIRGU NEAMT',
  YO7KBS: 'CLUBUL SPORTIV TERMO DROBETA-TURNU SEVERIN',
};

export const listdec3 = {
  YO2YA: 'MARIUS IOAN IACOB',
YO8SDC: 'DAICIU CRISTIAN DAN',
YO7BEM: 'DUMITROVICI MIHAI - BEM -',
YO2BLX: 'CHIS IOAN',
YO3DIU: 'MARIAN ARADOAEI',
YO3FFF: 'CRISTIAN NEGRU',
YO3GCL: 'OLTEANU MIHAI',
YO7AQF: 'AUGUSTIN PREOTEASA',
YO3CWT: 'DANIEL BORSOS',
YO7CW: 'MIHAI IOAN',
YO2MPK: 'URBAN RAUL',
YO2LTA: 'POPA DANIEL',
YO3LR: 'CRISTIAN SIMION',
YO3AF: 'FLORIN LUPOIU',
YO2CK: 'LASCONI OVIDIU CRISTINEL',
YO4BYW: 'STAN ALIMAN',
YO3JW: 'FENYO STEFAN',
YO7HYC: 'MANOLOIU VLAD',
YO3GJ: 'MATEIESCU ADRIAN MARIUS',
YO2CJX: 'VIRGIL NESTERIUC',
YO2LCV: 'IOAN MUNTEAN',
YO2CXJ: 'ANGELESCU PAUL GINEL',
YO9XC: 'BURDUCEA OVIDIU VASILE',
YO8YRY: 'MAFTEI IRINE',
YO7LRC: 'CHERESDI IOAN DORIN',
YO4DG: 'GABRIEL GIGEA',
YO2AMU: 'ZASLO DORU EMIL',
YO7PED: 'POPOVICI STEFAN',
YO6FNF: 'TICUSAN DAVID',
YO2LDU: 'FLORIN POPA',
YO6LRV: 'BERGER DENIS ADRIAN',
YO2BCO: 'BOGDANESCU CONSTANTIN',
YO4TL: 'VICTOR SARBU',
YO5DAS: 'CHIS MIHAI DANUT',
YO7HUZ: 'ALMASI NICOLAIE',
YO4SI: 'MIRCEA RUCAREANU',
YO2MJZ: 'CORNEL USCA',
YO4FPF: 'DAN ROCA',
YO4BXX: 'CONRAD CORNELIU',
YO2LUW: 'NUTI IULIU',
YO4GOU: 'DAN DRAGHIA',
YO9BI: 'CONSTANTIN NITA',
YO5PVZ: 'COROIAN AUGUSTIN',
YO9BHI: 'BELEI AUREL',
YO5YM: 'MARCEL VESA',
YO8BFB: 'VIOREL TOMOZEI',
YO8BDW: 'MILAN EDGAR CRASI',
YO5QDI: 'PETRU IVANCIUC',
YO9CWY: 'DAN MOTRONEA',
YO5AYT: 'PETRE IOAN',
YO8SJR: 'SILVIU NEAGU',
YO6OIW: 'MAGIRESCU IULIAN',
YO5OKM: 'MOLNAR LADISLAU',
YO2CLL: 'BEHAWETZ OTTO',
YO8BDV: 'DINCA VIOREL',
YO2LXW: 'MIHAI CAROL',
YO8TNC: 'GEORGE GUSUL',
YO3IHG: 'MICU-MATEI VLAD',
YO8MI: 'AILINCAI  CONSTANTIN',
YO9NIS: 'ENOAE DENIS ANDREI',
YO9IGV: 'GHEORGHIU VLADIMIR-IOAN',
YO4KAK: 'CS BRAILA (YO4AC)',
YO2KAR: 'C. S. SILVER FOX (YO2SH)',
YO2KBB: 'ADMIRA ARAD (YO1LFP & YO2LIE)',
YP20KQT: 'QSO BANAT (YO2MFC)',
YO8KZG: 'RADIOCLUB ION CREANGA (YO8RJU)',
};
