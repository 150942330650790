import {list1dec2024, listavramiancu2024, listcarnavallimba2024, listdec, listdec2, listdec3, listincoronare} from './cupa1dec';

export const activitati = [
  {
    id: 108,
    title: 'CONCURSUL OBICEIURI DE CRĂCIUN LA LIMBA',
    subtitle: '',
    sign: null,
    content: 'În data de 26 decembrie de la ora 19.00 la ora 20.00, Asociația LIMBENII, Primăria Ciugud și AJR DX Radio Alba au organizat concursul OBICEIURI DE CRĂCIUN LA LIMBA. Concursul s-a bucurat de o prezență foarte bună: 60 de stații din toată țara, mai multe decât la Campionatul Național. Concurenții vor primi diplome, broșuri de prezentare și popularizare: Limbenii - Pițărăii de la Limba și DVD-uri cu Corala Limbenii.\n' +
      'Diploma concursului:',
    photos: ['assets/diploma_craciun.jpg'],
    photosTop: null,
    pdf: [
      {
        link: 'assets/clasament_concurs.pdf',
        content: 'Clasament concurs'
      },
    ],
  },
  {
    id: 107,
    title: 'Regulament CONCURS OBICEIURI DE CRĂCIUN LA LIMBA',
    subtitle: '',
    sign: null,
    content: 'Organizator: Primăria Ciugud, Asociaţia Limbenii (YO5KAL) şi Asociaţia Judeţeană de Radioamatorism\n' +
      'DX Radio Alba;\n' +
      'Scop: Popularizarea OBICEIURILOR DE CRĂCIUN DIN SATUL LIMBA, COMUNA CIUGUD, JUDEȚUL ALBA\n' +
      'Desfășurare: 26 DECEMRIE 2024, orele 17:00 - 18:00 UTC, în două etape de câte o jumătate de oră;\n' +
      'Benzi si moduri de lucru: 3.5 MHz, SSB.\n' +
      'Pentru acest concurs puterea maximă de emisie este de 100W!\n' +
      'Categorii de participare:\n' +
      'A. Seniori;\n' +
      'B. Juniori;\n' +
      'C. Staţii de club;\n' +
      'D. Receptori.\n' +
      '* JUNIORI = participanţi cu vârsta sub 18 ani la data concursului;\n' +
      '* În antetul logului, la Category: se va trece una din literele: A, B, C sau D.\n' +
      'Controale: RS + 001 + prescurtare judeţ.\n' +
      'Punctaj: 1 QSO = 2 pct. Acelaşi punctaj și pentru receptori, cu condiția recepționării unui QSO\n' +
      'complet. Staţiile din judeţul Alba acordă pentru fiecare QSO câte 5 pct.\n' +
      'Multiplicator: Fiecare judeţ + cel propriu + stația YO5KAL (AL);\n' +
      'Scor pe etapă: suma punctelor x suma multiplicatorilor pe etapă ;\n' +
      'Scor Final: suma scorurilor din cele 2 etape;\n' +
      'Clasamente/premii: Primii 3 clasaţi la fiecare categorie primesc diplome tipărite şi premii surpriză.\n' +
      'Toți participanţii primesc diplome in format electronic care se vor descărca de la adresa:\n' +
      'https://www.albadxradio.ro/concursuri precum și premii surpriză. Staţia YO5KAL nu intră în\n' +
      'clasament.\n' +
      'Program de arbitraj: Dan, YO9CWY.\n' +
      'Termen/adresă: Logurile în format Cabrillo se vor trimite în termen de 10 zile la adresa de e-mail:\n' +
      'yo5ddd@yahoo.com.',
    photos: [],
    photosTop: null,
    pdf: [],
  },
  {
    id: 106,
    title: 'Cupa 1 Decembrie 2024',
    sign: null,
    content: 'A devenit deja o tradiție ca în fiecare an să ne întâlnim la 1 Decembrie, pentru a sărbători Ziua Națională, prin concursul Cupa 1 Decembrie. Si anul acesta 52 dintre prietenii noștri au fost prezenți în eter cu gânduri bune, celebrând marele eveniment petrecut acum 106 ani. Chiar dacă participarea a fost puțin mai redusa entuziasmul a fost unul extraordinar.\n' +
      ' Cupa a fost castigata de YO3GCL, însă, noi, organizatorii îi consideram câștigatori pe toți cei care au participat. De pe această pagină puteți diploma de participare.\n' +
      'Mulțumim de asemenea tuturor concurenților pentru participare și pentru gândurile bune transmise.\n',
    photos: [],
    pdf: [
      {
        link: 'assets/1_dec_2024_clasament.pdf',
        content: 'Clasament concurs Cupa 1 Decembrie - 2024'
      },
    ],
    generatorList: list1dec2024,
    textProps: {
      r: 256,
      g: 256,
      b: 256,
      fontSize: 19,
    },
    texts: {
      indicativ: {
        x: 190,
        y: 83,
      },
      nume: {
        x: 92,
        y: 95,
      }
    },
    img: {
      height: 210,
      wigth: 300,
    },
    orientation: 'l',
    generatorDiploma: 'assets/1dec2024_diploma.jpg'
  },
  {
    id: 105,
    title: 'CONCURS CARNAVALUL DE LA LIMBA (NOAPTEA PRICOLICILOR)',
    sign: null,
    content: 'Primăria şi Consiliului local al Comunei Ciugud, în colaborare cu Parohia Ortodoxă Limba şi Asociaţia Limbenii, au organizat vineri 28 iunie 2024, în satul Limba, în parc, CARNAVALUL DE LA LIMBA (Noaptea Pricolicilor-ediţia a XVIII-a), serbare în aer liber care scoate în evidenţă originile dacice ale limbenilor şi bucuria păstrării unor obiceiuri străvechi, o veritabilă lecţie de istorie „live”. Legenda “Pricolicilor” este, de fapt, amintirea, deformată şi reinterpretată, a unor ritualuri de iniţiere a tinerilor “lupi”, adică al tinerilor daci organizaţi în asociaţii bărbăteşti închise. În cadrul acestor asociaţii, membrii erau supuşi unor teste cu scopul de a-i face să semene cu carnasiera pe care şi-au ales-o ca emblemă. Aceste ritualuri au durat destul de mult în timp, astfel încât limbenii au ajuns să fie cunoscuţi sub denumirea de oameni-lup sau, din superstiţie, pricolici.\n' +
      'Cu acestă ocazie, Radioclubul Asociației Limbenii – YO5KAL a organizat acest concurs, ca un mijloc de promovare a tradițiilor și identității limbenilor. Mulțumim de asemenea tuturor concurentilor pentru participare și pentru gândurile bune transmise.\n',
    photos: [],
    pdf: [
      {
        link: 'assets/Clasament Carnavalul de la Limba.txt',
        content: 'Clasament CONCURS CARNAVALUL DE LA LIMBA (NOAPTEA PRICOLICILOR)'
      },
    ],
    generatorList: listcarnavallimba2024,
    textProps: {
      r: 2,
      g: 2,
      b: 222,
      fontSize: 19,
    },
    texts: {
      indicativ: {
        x: 190,
        y: 80,
      },
      nume: {
        x: 92,
        y: 90,
      }
    },
    img: {
      height: 210,
      wigth: 300,
    },
    orientation: 'l',
    generatorDiploma: 'assets/DIPLOMA Carnavalul de la Limba-1.png'
  },
  {
    id: 104,
    title: 'CONCURS OMAGIAL “AVRAM IANCU”',
    sign: null,
    content: 'Avram Iancu, această personalitate proeminentă a paşoptismului românesc, de-alungul întregii sale vieți a făcut o referire specială la situația românilor din Imperiul Habsburgic. Personalitate sa a fost mereu urmată ṣi apreciată deopotrivă de contemporani, dar şi de urmaşi. "Nimic nu a fost mai important pentru Crăişorul Munților decât libertatea poporului său, afirmarea sa deplină în raport cu celelalte națiuni, pe baza egalității între acestea".\n' +
      'La acest concurs omagial 26 dintre prietenii noștri au fost prezenti în eter cu gânduri bune, celebrând astfel 200 de ani de la nașterea marelui om politic, personalitate emblematică a pașoptismului românesc. Chiar dacă participarea a fost puțin mai redusă, considerăm că emoția și entuziasmul au fost deosebite.\n' +
      'Mulțumim de asemenea tuturor concurenților atât pentru participare, cât si pentru gândurile bune transmise.\n',
    photos: [],
    pdf: [
      {
        link: 'assets/clasament_avram_iancu_2024.txt',
        content: 'Clasament concurs Avram Iancu - 2024'
      },
    ],
    generatorList: listavramiancu2024,
    textProps: {
      r: 255,
      g: 255,
      b: 255,
      fontSize: 19,
    },
    texts: {
      indicativ: {
        x: 190,
        y: 80,
      },
      nume: {
        x: 80,
        y: 93,
      }
    },
    img: {
      height: 210,
      wigth: 300,
    },
    orientation: 'l',
    generatorDiploma: 'assets/DIPLOMA_AVRAM IANCU_OK2-1.png'
  },
  {
    id: 103,
    title: 'Regulament CONCURS CARNAVALUL DE LA LIMBA',
    subtitle: '(NOAPTEA PRICOLICILOR)',
    sign: null,
    content: 'Organizator: Primăria Ciugud, Asociația Limbenii (YO5KAL) și Asociația Județeană de Radioamatorism DX Radio Alba;\n' +
      'Scop: Popularizarea CARNAVALULUI DE LA LIMBA (NOAPTEA PRICOLICILOR);\n' +
      'Desfășurare: sâmbătă, 29 IUNIE 2024, orele 15:00 - 17:00 UTC, în două etape de câte o oră;\n' +
      'Benzi și moduri de lucru: 3.5Mhz, SSB;\n' +
      'Pentru acest concurs puterea maximă de emisie 100W!\n' +
      'Categorii de participare: \n' +
      'Participanți: La acest eveniment pot participa toți radioamatorii români cu autorizație valabilă la momentul desfăşurării:\n' +
      '\tA. Seniori \n' +
      '\tB. Juniori\n' +
      '\tC. Stații de club\n' +
      '\tD. Receptori\n' +
      '\tJUNIORI = participanti cu varsta sub 18 ani la data concursului\n' +
      'Controale: RS + 001 + prescurtare județ, sau AA pentru stațiile care nu sunt pe teritoriul României.\n' +
      'Stația YO5KAL transmite 59 + 001 + codul AL (ASOCIAȚIA LIMBENII)\n' +
      'Punctaj 1 QSO = 2 pct. Stațiile din judetul Alba acordă pentru fiecare QSO câte 5 pct.\n' +
      'Receptorii primesc 2 puncte pe QSO, cu conditia receptionarii unui QSO complet.\n' +
      'Multiplicator: Fiecare județ + cel propriu + stația YO5KAL (AL).\n' +
      'Scor pe etapă: suma punctelor x suma multiplicatorilor pe etapă.\n' +
      'Scor final: suma scorurilor din cele 2 etape.\n' +
      'Clasamente / premii: Primi 3 clasați la fiecare categorie primesc diplome tipărite și premii surpriză. Toți participanții primesc diplome in format electronic, care se vor descărca de la adesa: www.albadxradio.ro la secțiunea ACTIVITĂȚI / CONCURSURI. YO5KAL nu intră în clasament. \n' +
      'Program de arbitraj: Dan YO9CWY\n' +
      'Termen / adresa:  Logurile în format Cabrillo se vor trimite in 10 zile la adresa email: yo5ddd@yahoo.com.',
    photos: [],
    photosTop: null,
    pdf: [],
  },
  {
    id: 102,
    title: 'Regulament CONCURS OMAGIAL “AVRAM IANCU”',
    sign: null,
    content: 'Avram Iancu (n. 1824, Avram Iancu, Alba, Romania - d. 10 septembrie 1872) a fost un avocat transilvănean și\n' +
      'revoluționar român pașoptist, care a jucat un rol important în Revoluția de la 1848 din Transilvania.' +
      '\n\nOrganizator: Asociația Județeană de Radioamatorism DX Radio Alba\n' +
      'Scop: aniversarea a 200 de ani de la nașterea lui Avram Iancu, cel supranumit Crăișorul munților.\n' +
      'Desfășurare: sâmbătă, 13 IULIE 2024, între orele 16:00 - 18:00 UTC, în două etape de câte o oră.\n' +
      'Benzi și moduri de lucru: 3.5 MHz, SSB\n' +
      'Pentru acest concurs puterea maximă de emisie = 100W!\n' +
      'Categorii de participare:\n' +
      '\tA Seniori\n' +
      '\tB Juniori (participanți cu vârsta sub 18 ani la data concursului)\n' +
      '\tC Stații de club\n' +
      '\tD Receptori\n' +
      '(în antetul logului, la Category se va trece una din literele A, B, C, sau D)\n' +
      'Controale: RS + 001 + prescurtare județ, sau AA pentru stațiile care nu sunt pe teritoriul României.\n' +
      'Stația omagială YQ5A transmite 59 + 001 + codul AI (Avram Iancu)\n' +
      'Punctaj: 1 QSO = 2 pct. Stațiile din județul Alba acordă pentru fiecare QSO câte 5 pct. Același punctaj și\n' +
      'pentru receptori, cu condiția recepționării unui QSO complet.\n' +
      'Multiplicator: Fiecare județ + &quot;AI&quot; + cel propriu\n' +
      'Scor pe etapa: suma punctelor x suma multiplicatorilor pe etapă\n' +
      'Scor Final: suma scorurilor din cele 2 etape\n' +
      '* Clasamente / premii: Primii 3 clasați la fiecare categorie primesc diplome tipărite și premii surpriză. Toți\n' +
      'participanții primesc diplome in format electronic care se vor descărca de la adresa:\n' +
      'https://www.albadxradio.ro/concursuri. Stația YQ5A nu intră în clasament.\n' +
      '*Program de arbitraj: Dan, YO9CWY\n' +
      'Termen / adresa: Logurile în format Cabrillo se vor trimite în termen de 10 zile la adresa e-mail:\n' +
      'yo5ddd@yahoo.com.',
    photosTop: 'assets/Avram_Iancu.jpg',
    photos: [],
  },
  {
    id: 101,
    title: 'Clasament Concurs "Lucian Blaga" 2024',
    sign: null,
    biggerImage: true,
    content: '',
    photos: ['assets/2.png'],
  },
  {
    id: 100,
    title: 'CUPA 1 DECEMBRIE - 2023',
    sign: null,
    content: 'A devenit deja o tradiție ca în fiecare an să ne întâlnim la 1 Decembrie, dupa amiaza, pentru a sărbători Ziua Națională, ' +
      'prin concursul Cupa 1 Decembrie. Si anul acesta 68 dintre prietenii noștri au fost prezenti in eter cu ganduri bune, celebrând ' +
      'marele eveniment petrecut acum 105 ani. Chiar dacă participarea a fost puțin mai redusa fata de ultimii ani, dar entuziasmul a ' +
      'fost unul extraordinar.\n' +
      'Cupa a fost castigata de YO2YA, insa, noi, organizatorii îi consideram castigatori pe toti participantii. Pentru prima data, ' +
      'dupa 31 de ani concursul a fost arbitrat cu un soft dedicat, creat de YO9CWY, căruia îi multumim cu tot respectul. Acum puteti ' +
      'descarca de pe aceasta pagina logul de concurs pentru a-l folosi in viitor, precum si diploma de participare.\n' +
      'Multumim de asemenea tuturor concurentilor pentru participare si pentru gandurile bune transmise.',
    photos: [],
    pdf: [
      {
        link: 'assets/Cupa_1Dec_Clas_2023.pdf',
        content: 'Clasament concurs Cupa 1 Decembrie - 2023'
      },
    ],
    generatorList: listdec3,
    textProps: {
      r: 255,
      g: 255,
      b: 255,
      fontSize: 19,
    },
    texts: {
      indicativ: {
        x: 195,
        y: 83,
      },
      nume: {
        x: 103,
        y: 93,
      }
    },
    img: {
      height: 210,
      wigth: 300,
    },
    orientation: 'l',
    generatorDiploma: 'assets/Diploma1Dec2023.png'
  },
  {
    id: 101,
    title: 'Regulament CUPA 1 DECEMBRIE - 2023',
    sign: null,
    content: 'Organizator: Asociatia Judeteana de Radioamatorism DX Radio Alba.\n' +
      'Desfasurare: 1 Decembrie in fiecare an, orele 14:00 - 16:00 UTC, in doua etape de cate o ora.\n' +
      'Benzi si moduri de lucru: SSB = 3675 – 3775 kHz.\n' +
      'Pentru acest concurs puterea maxima de emisie 100W!\n' +
      'Categorii de participare:\n' +
      'A. seniori;\n' +
      'B. juniori - participanti cu varsta sub 18 ani la data concursului;\n' +
      'C. statii de club (1 - 2 operatori);\n' +
      'D. receptori.\n' +
      'Controale: RS + 001 + prescurtare judet, sau AA pentru statiile care nu sunt pe teritoriul Romaniei.\n' +
      'Punctaj: 1 QSO = 2 pct.\n' +
      'Acelasi punctaj si pentru receptori, cu conditia receptionarii unui QSO complet.\n' +
      'Multiplicator: Fiecare judet + cel propriu + statia YQ0U.\n' +
      'Scor pe etapa = suma punctelor x suma multiplicatorilor pe etapa\n' +
      'Scor Final = suma scorurilor din cele 2 etape\n' +
      'Clasamente / premii: Clasamente separate pentru fiecare categorie. Primii 3 clasati la fiecare categorie primesc diplome. YQ0U nu' +
      ' intră în clasamente. Statia cu scorul cel mai mare va primi Cupa 1 Decembrie.\n' +
      'Concurentii care îndeplinesc conditiile pentru diploma 1 Decembrie 1918, o pot primi in format electronic, în baza unuei cereri ' +
      '( mail ) anexata la fisele de concurs.\n' +
      'Termen / adresa:\n' +
      'Logurile (recomandabil in format Cabrillo) se vor trimite in 10 zile la adresa: Popa Vasile, Aiud, str. Florilor nr. 9,' +
      ' cod 515200, email: yo5ddd@yahoo.com\n' +
      'Arbitraj program YO9CWY.\n',
    photos: [],
  },
  {
    id: 102,
    title: 'Concurs Lucian Blaga - 2023',
    photos: [],
    content: '',
    pdf: [
      {
        link: 'assets/Concurs LUCIAN BLAGA 2023.pdf',
        content: 'Clasament concurs Lucian Blaga 2023'
      },
    ],
    link: false,
  },
  {
    id: 108,
    title: 'Regulament CONCURS LUCIAN BLAGA 2023',
    photos: ['assets/1.png'],
    content: 'Organizatori: Primaria Municipiului Sebes si Radioclubul Centrului Cultural Lucian Blaga Sebes-YO5KLB Desfasurare: ziua de Sambata, cea mai apropiata de 9 Mai (11 Mai 2024, 10 mai 2025 ), in doua etape:\n' +
      '- Etapa I-a orele 15:00-15:59;\n' +
      '- Etapa II-a orele 16:00-16:59 UTC. Banda si mod de lucru: 80m, SSB, in portiunea de banda 3675 - 3775 MHz.\n' +
      'Putere: Maxim: 100 W. Categorii de participare:\n' +
      'A - Seniori\n' +
      'B - Juniori\n' +
      'C - Statii de club D - Receptori\n' +
      'Controale: RS + 001 + prescurtare judet : AA pentru statiile din afara YO, BU pentru Bucuresti, LB pentru statia aniversara + titlul unei opere scrisa de Lucian Blaga (poezii, eseuri, proza, teatru, etc.) , altul la fiecare legatura.\n' +
      'Punctaj: 1 QSO = 2 puncte\n' +
      'Acelasi punctaj si pentru receptori , pentru receptia unui QSO complet\n' +
      'Multiplicator: Fiecare judet + cel propriu + statia YO5KLB (LB)\n' +
      'Scor pe etapa: suma punctelor x suma multiplicatorilor pe etapa\n' +
      'Scor Final: suma scorurilor din cele 2 etape\n' +
      'Clasamente:\n' +
      'Primele trei locuri primesc Cupa (Placheta) “Lucian Blaga “, si alte premii in functie de “ starea de spirit “ a sponsorilor.\n' +
      'Termen / Adresa :\n' +
      'maxim 10 zile, loguri in format Cabrillo la adresa: yo5ddd@yahoo.com\n' +
      'Titlurile operelor transmise in legaturi nu se vor trece in log\n' +
      'Program de arbitraj: Dan, yo9cwy\n' +
      'Obs: la Category se trece una din literele A, B, C sau D\n' +
      ' \n' +
      'Modelul de log il gasiti in imaginea de mai jos. \n' +
      'Numele fisierului trimis ca atasament: YO2AMU.cbr (indicativ.cbr)\n',
  },
  {
    id: -4,
    title: 'CUPA 1 DECEMBRIE - 2022',
    sign: null,
    content: 'Asociația Județeană de Radioamatorism Alba sărbătorește prin intermediul undelor radio Ziua Natională a României, stabilind legaturi în eter cu radioamatori din toate colțurile lumii, transmițând prin acestea, semnificația istorică a evenimentului. Din 1990 și până azi, echipe de radioamatori din asociație, au lucrat în ajunul sărbătorii, cu indicativul special YQ0U, adunând mii de legături pe care le-au confirmat cu cărți speciale de confirmare (QSL), pe care le-au expediat tuturor celor cu care au avut contacte radio. Am fost sprijiniți în anii trecuți de Consiliul Județean Alba, care ne-a asigurat resursele financiare necesare, dar în ultimii ani am reușit cu greu, din resurse proprii, să asigurăm banii necesari pentru tipărirea și expedierea QSL-urilor Anul acesta însă, două firme private, care nu au dorit să le publicăm numele, ne-au sponsorizat cu aproape toată suma necesară, astfel că ne va fi mai ușor sa atingem scopul acestei frumoase acțiuni, considerând mereu că este o datorie de onoare a radioamatorilor din Alba Iulia și județul Alba să promoveze cu mândrie Ziua Națională a României.',
    photos: [],
    pdf: [
      {
        link: 'assets/Clasament concurs Cupa 1 Decembrie - 2022.docx',
        content: 'Clasament concurs Cupa 1 Decembrie - 2022'
      },
      {
        link: 'assets/Comentarii ale participantilor Cupa 1 Decembrie - 2022.docx',
        content: 'Comentarii ale participantilor Cupa 1 Decembrie - 2022'
      },
    ],
    generatorList: listdec2,
    textProps: {
      r: 255,
      g: 255,
      b: 255,
      fontSize: 19,
    },
    texts: {
      indicativ: {
        x: 195,
        y: 83,
      },
      nume: {
        x: 103,
        y: 93,
      }
    },
    img: {
      height: 210,
      wigth: 300,
    },
    orientation: 'l',
    generatorDiploma: 'assets/1 Decembrie 22.png'
  },
  {
    id: 103,
    title: 'CUPA ÎNCORONĂRII REGELUI FERDINAND',
    sign: null,
    content: '',
    photos: [],
    pdf: [
      {
        link: 'assets/CUPA CENTENARUL INCORONARII REGELUI FERDINAND FINAL.txt',
        content: 'Clasament concurs Cupa Încoronării Regelui Ferdinand'
      },
    ],
    generatorList: listincoronare,
    textProps: {
      r: 0,
      g: 0,
      b: 0,
      fontSize: 19,
    },
    texts: {
      indicativ: {
        x: 120,
        y: 110,
      },
      nume: {
        x: 96,
        y: 123,
      }
    },
    img: {
      height: 298,
      wigth: 210,
    },
    orientation: 'p',
    generatorDiploma: 'assets/incoronare.png'
  },
  {
    id: 104,
    title: 'Regulament: CUPA CENTENARUL ÎNCORONĂRII REGELUI FERDINAND',
    sign: null,
    content: 'Organizator: Asociația Județeană de Radioamatorism DX Radio Alba.\n' +
      'Desfășurare: Sâmbătă, 15 octombrie 2022, orele 14:00 - 16:00 UTC, în două etape de câte o oră.\n' +
      'Benzi și moduri de lucru: 3.5Mhz, SSB, CW, FT 8.\n' +
      'Pentru acest concurs puterea maximă de emisie 100W!\n' +
      'Categorii de participare: \n' +
      'A. seniori SSB \n' +
      'B. seniori CW\n' +
      'C. seniori FT8\n' +
      'D. juniori SSB\n' +
      'E. juniori CW\n' +
      'F. juniori FT8         Junior = participant cu vârsta sub 18 ani la data concursului\n' +
      'G. statii de club SSB\n' +
      'H. statii de club CW\n' +
      'I. statii de club FT8\n' +
      'J. receptori toate cele 3 moduri de lucru (o singură categorie)\n' +
      'Controale: RS + 001 + prescurtare județ, sau AA pentru stațiile care nu sunt pe teritoriul Romaniei.\n' +
      'Punctaj: 1 QSO = 2 pct. Același punctaj și pentru receptori, cu condiția recepționării unui QSO complet.\n' +
      'Multiplicator: Fiecare județ + cel propriu + stația YR100REX. Scor final: Suma punctelor din legaturi X suma multiplicatorilor din cele 2 etape.\n' +
      'Clasamente/premii: Clasamente separate pentru fiecare categorie. YR100REX nu intră în clasamente. Primii 3 clasați la fiecare categorie vor primi diplome. Stația cu scorul cel mai mare va primi Cupa Centenarul Încoronării Regelui Ferdinand.\n' +
      '\n' +
      'ATENTIE!\n' +
      'ORICINE POATE LUCRA IN TOATE MODURILE DE LUCRU, DAR PENTRU CUPĂ SE I-A IN CALCUL PUNCTAJUL CEL MAI MARE OBȚINUT NUMAI LA UNUL DIN MODURILE DE LUCRU (PUNCTAJELE NU SE CUMULEAZA PENTRU CUPA).\n' +
      '\n' +
      'Toți participanții vor primi diplome de participare în format electronic care se vor descărca de la adresa: https://www.albadxradio.ro/concursuri.\n' +
      '\n' +
      'Termen/adresă: Logurile în format Cabrillo (și dacă se lucrează în mai multe moduri un singur log) se vor trimite în termen de 10 zile la adresa de email: yo5ddd@yahoo.com.',
    photos: [],
  },
  {
    id: 105,
    title: 'Concurs Lucian Blaga - 2022',
    photos: [],
    content: '',
    pdf: [
      {
        link: 'assets/Concurs LUCIAN BLAGA 2022.pdf',
        content: 'Clasament concurs Lucian Blaga 2022'
      },
    ],
    link: false,
  },
  {
    id: 106,
    title: 'CUPA 1 DECEMBRIE - 2021',
    sign: null,
    content: 'Concursul Cupa 1 Decembrie s-a bucurat de o larga participare (96 participanți din care 2 din DL), 1 junior, 8 cluburi, 86 seniori și 1 log control. Primii clasați vor primi diplome, iar stația cu cel mai mare punctaj va primi CUPA 1 DECEMBRIE. Mulțumim tuturor pentru participare, îi invităm la acest concurs aniversar și în anii care urmează, iar ca recunoaștere a participării la concurs, le oferim diploma “1 Decembrie 1918” pe care o puteți descărca de aici.',
    photos: [],
    pdf: [
      {
        link: 'assets/CLASAMENT CUPA 1 DECEMBRIE  2021.TXT',
        content: 'Clasament concurs Cupa 1 Decembrie - 2021'
      },
      {
        link: 'assets/COMENTARII ALE  PARTICIPANTILOR.pdf',
        content: 'Comentarii ale participantilor Cupa 1 Decembrie - 2021'
      },
    ],
    generatorList: listdec,
    textProps: {
      r: 255,
      g: 255,
      b: 255,
      fontSize: 19,
    },
    texts: {
      indicativ: {
        x: 195,
        y: 83,
      },
      nume: {
        x: 103,
        y: 93,
      }
    },
    img: {
      height: 210,
      wigth: 300,
    },
    orientation: 'l',
    generatorDiploma: 'assets/DIPLOMA2021DEC.png'
  },
  {
    id: 107,
    title: 'Regulament CUPA 1 DECEMBRIE',
    sign: null,
    content: 'Organizator: Asociația Județeană de Radioamatorism Alba;\n' +
      'Desfășurare: 1 Decembrie în fiecare an, orele 14:00 - 16:00 UTC, în două etape de câte o oră;\n' +
      'Benzi și moduri de lucru: SSB = 3675 – 3775 kHz.\n' +
      '\n' +
      'Pentru acest concurs vă rugăm limitați puterea maxima de emisie la 100W!\n' +
      '\n' +
      'Categorii de participare:\n' +
      'A.\tseniori\n' +
      'B.\tjuniori\n' +
      'C.\tstații de club (1-2 operatori)\n' +
      'D.\treceptori\n' +
      '\n' +
      'Controale: RS + 001 + prescurtare județ, sau AA pentru stațiile care nu sunt pe teritoriul României.\n' +
      'Punctaj: 1 QSO = 2 pct.\n' +
      'Acelasi punctaj și pentru receptori, cu condiția recepționării unui QSO complet.\n' +
      'Multiplicator: Fiecare județ + cel propriu + statia YQ0U.\n' +
      'Scor final: Suma punctelor din legături x suma multiplicatorilor din cele 2 etape.\n' +
      'Clasamente/premii: Clasamente separate pentru fiecare categorie. Primii 3 clasați la fiecare categorie primesc diplome. YQ0U nu intră în clasamente. Stația cu scorul cel mai mare va primi Cupa 1 Decembrie.\n' +
      ' \n' +
      'Concurenții care îndeplinesc condițiile pentru diploma 1 Decembrie 1918, o pot primi gratuit, în format electronic, în baza unui extras de log anexat la fișele de concurs.\n' +
      '\n' +
      'Termen/adresa: \n' +
      'Logurile (recomandabil in format Cabrillo) se vor trimite în 10 zile la: YO5DDD - Popa Vasile, Str. Florilor nr. 9, RO 515200 Aiud, jud. Alba, tel: 0723.201-367, E-mail: yo5ddd@yahoo.com.\n',
    photos: [],
  },
  {
    id: 108,
    title: 'Regulament CONCURS LUCIAN BLAGA',
    photos: [],
    content: 'Organizator: Primăria Municipiului Sebeș și Radioclubul Centrului Cultural Lucian Blaga Sebeș - YO5KLB.\n' +
      'Desfășurare: Data de --- Mai, două etape: Etapa I-a: ora 15:00 - 15:59; Etapa II-a ora: 16:00 - 16:59 UTC.\n' +
      'Banda și mod de lucru: 80m, SSB în porțiunea de bandă 3675 – 3775 Mhz.\n' +
      'Putere: Maxim 100W.\n' +
      'Categorii de participare: A-seniori, B-juniori, C-cluburi, D-receptori.\n' +
      'Controale: RS+001(și în continuare) + prescurtare județ + titlul unei opere scrisa de Lucian Blaga (poezii, eseuri, proză, teatru, etc.) altul la fiecare legătură.\n' +
      'Punctaj: 1 punct/QSO.\n' +
      'Multiplicator: Numărul de județe + numărul de stații din județul Alba.\n' +
      'Scor final: Suma punctelor din legături x suma multiplicatorilor.\n' +
      'Clasamente: Primele trei locuri primesc Cupa (Placheta) “Lucian Blaga“, și alte premii în funcție de “starea de spirit“ a sponsorilor.\n' +
      'Termen/Adresa: 10 zile; loguri în format cabrillo la yo9hg@yahoo.com - arbitrul concursului, loguri în alte formate la yo5gha@yahoo.com sau Utea Dan, Bd. Lucian Blaga nr. 16A, RO-515800, Sebeș, jud. Alba.\n' +
      'Titlurile operelor transmise în legături nu se vor trece în log.\n' +
      'Notă: Stațiile care nu vor respecta regulamentul concursului vor fi incluse la log control.\n',
  },
  {
    id: 2,
    title: 'Concurs Lucian Blaga - 2021',
    photos: ['assets/images/activitati/14/0.jpg', 'assets/images/activitati/14/1.jpg', 'assets/images/activitati/14/2.jpg', 'assets/images/activitati/14/3.jpg', 'assets/images/activitati/14/4.jpg', 'assets/images/activitati/14/5.jpg'],
    content: '',
    pdf: [
      {
        link: 'assets/Concurs LUCIAN BLAGA 2021.pdf',
        content: 'Clasament concurs Lucian Blaga 2021'
      },
    ],
    link: true,
  },
  {
    id: 109,
    content: 'Se acordă stațiilor de radioamator române și străine (emisie-receptie și recepție) care îndeplinesc în perioada 20.11 - 02.12, următoarele condiții cumulate:\n' +
      '1. Stabilesc o legătură cu stația omagială YQ0U;\n' +
      '2. Realizează 9  legături astfel:\n' +
      '- 3 legături cu stații din 3 județe diferite din Transilvania;\n' +
      '- 3 legături cu stații din 3 județe diferite din Muntenia;\n' +
      '- 3 legături cu stații din 3 județe diferite din Moldova. \n' +
      '\n' +
      'Notă:\n' +
      'Diploma, în format electronic se acordă anual, o singura dată, indiferent de bandă sau mod de lucru;\n' +
      'Condițiile de acordare pot fi îndeplinite și în concursul «Cupa 1 Decembrie»;\n' +
      'Cererile și logurile se vor trimite până la 20 Decembrie pe adresa de mail: ajralba@yahoo.com\n',
    title: 'Regulament de acordare a DIPLOMEI 1 Decembrie 1918',
    photos: ['assets/images/activitati/2/diploma_1dec_2010.jpg']
  },
  {
    id: 110,
    title: 'Regulament de acordare a diplomei Lucian Blaga',
    content: 'Se acordă anual stațiilor de radioamatori române și străine pentru realizarea unui număr de puncte corespunzător numărului de ani împliniți de la nașterea marelui filosof și poet român (anul 2007 = 112 ani = 112 puncte), astfel:\n' +
      '1. Stația cu indicativul special YP0LB = 10 p;\n' +
      '2. Stațiile din județul Alba = 5 p;\n' +
      '3. Stațiile din orașele: Bucuresti; Brașov; Sibiu; Cluj; Lugoj = 5 p;\n' +
      '4. Alte statii YO = 1 p.\n' +
      'Sunt valabile legăturile de radio bilaterale realizate în perioada 1-10 mai, în fiecare an.\n' +
      'Stațiile care acordă puncte sunt luate în calcul o singură dată pe fiecare banda și mod de lucru.\n' +
      'Pentru stațiile de recepție sunt valabile numai legăturile complete și se punctează numai un singur indicativ / QSO.\n' +
      '\n' +
      'Notă:\n' +
      'Diploma in format electronic, se acordă anual, o singura dată, indiferent de bandă sau mod de lucru.\n' +
      'Cererile si  logurile se vor trimite pana la 25 mai pe adresa de mail: yo5gha@yahoo.com.\n' +
      '\n',
    photos: ['assets/images/activitati/10/0.jpg'],
  }
];
